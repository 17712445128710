import { type ClientLoaderFunctionArgs, redirect } from '@remix-run/react';

import { EnumsTokenExchangePlatform } from '@lp-lib/api-service-client/public';

import { LoadingSpinner } from '../components/LoadingSpinner';
import { apiService } from '../services/api-service';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);
  const code = url.searchParams.get('code');
  if (!code) {
    throw new Response('No code was provided', {
      status: 400,
    });
  }
  const resp = await apiService.auth.executeTokenExchange({
    platform: EnumsTokenExchangePlatform.TokenExchangePlatformZoom,
    code,
  });
  return redirect(resp.data.deeplink);
};

export function Component() {
  return <LoadingSpinner />;
}
